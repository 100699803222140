<template>
  <div>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0"
      icon="mdi-bed"
      color="danger"
      text
    >
      Es gibt {{ items.length }} offene Entschuldigungen!
    </v-alert>
    <v-card
      :class="`v-alert--text danger--text rounded-t-0`"
      flat
      tile
      :loading="loading"
    >
      <v-divider />
      <v-card-actions>
        <v-btn
          dark
          color="danger"
          text
          block
          small
          :to="
            $_hasRole('student')
              ? { name: 'StudentAbsences' }
              : { name: 'Absences' }
          "
          >reagieren</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = [];
      for (const schoolClass of this.$_classTeacherOf) {
        this.items.push(
          ...(await this.apiList({
            resource: "absence/justification",
            query: "pending&schoolclass=" + schoolClass.id,
          }))
        );
      }

      if (this.items.length > 0) {
        this.$emit("loaded");
      }
      this.loading = "true";
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
